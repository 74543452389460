<template>
  <Sheet value="value" :width="taskWidth" @input="closeSheet">
    <template #title>
      <slot name="title">Edit Reports</slot>
    </template>

    <template #actions>
      <BaseActionButton
        is-flat
        :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
        class="q-mr-sm"
        @click="toggleWidth"
      />
    </template>

    <!-- form -->
    <template #default>
      <div class="q-pa-md">
        <!-- <BaseScrollbar height="calc(100vh - 79px)" class="q-pa-md"> -->
        <RenderForm
          no-padding
          :panels="panels"
          :secondary-panels="secondaryPanels"
          form-settings="formSettings"
          form-model="taskModel"
          is-readonly="mode === 'VIEW'"
          @save="saveTask"
        />
        <!-- </BaseScrollbar> -->
      </div>
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";

export default {
  name: "AddRports",

  components: { Sheet, RenderForm },

  props: {
    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      taskWidth: "720px",
      panels: [
        {
          id: this.$nano.id(),
          type: "Date",
          label: "Start Date",
        },
      ],
      secondaryPanels: [
        {
          id: this.$nano.id(),
          type: "Date",
          label: "Start Date",
        },
      ],
    };
  },

  computed: {
    isMaximized() {
      return this.taskWidth === "100vw";
    },
  },

  methods: {
    toggleWidth() {
      this.taskWidth = this.taskWidth === "100vw" ? "720px" : "100vw";
    },

    closeSheet() {
      this.$router.push({
        name: "reports-list",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
